import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueTag from 'vue-gtag'
import info from '../src/assets/info.json'

Vue.use(VueTag, {
  config: { id: info.googleAnalyticsTag }
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')